import _ from 'lodash';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { BiDownArrowAlt } from 'react-icons/bi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IInvestor from '~Api/Investor/IInvestor';
import { currentInvestorGetAction, monthlyStatementsListAction } from '~Investor/actions';
import { currentInvestorSelector, monthlyStatementsSelector } from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import moment from 'moment';
import Pagination from '~UI/Pagination';

interface IState {
    currentPage: number;
    perPage: number;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    monthlyStatements: IDictionary<IMonthlyStatement>;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    monthlyStatementsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class MonthlyStatements extends React.Component<Props, IState> {
    public state: IState = {
        currentPage: 1,
        perPage: 3,
    };

    constructor(props: Props) {
        super(props);

        this.onClickPage = this.onClickPage.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor, monthlyStatements } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!monthlyStatements) {
            this.props.monthlyStatementsList();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, monthlyStatements } = this.props;
        const { currentPage, perPage } = this.state;

        if (!monthlyStatements || !currentInvestor) {
            return (
                <div className='content-box'>
                    <Spinner animation='border' />
                </div>
            );
        }

        const offset: number = (currentPage - 1) * perPage;
        const pageMonthlyStatements: IMonthlyStatement[] = _.values(monthlyStatements).slice(offset, offset + perPage);

        const statementsBlock: JSX.Element[] = _.map(_.orderBy(pageMonthlyStatements, ['year'], ['desc']), (monthlyStatement: IMonthlyStatement) => (
            <Button
                href={`${process.env.API_HOST}/investor-account-monthly-statements/${monthlyStatement.uuid}/download`}
                target='_blank'
            >
                {moment(monthlyStatement.month).format('MMMM')} {monthlyStatement.year} Monthly Statement <BiDownArrowAlt />
            </Button>
        ));

        return (
            <div className='content-box'>
                <h2>Monthly Statements</h2>
                {statementsBlock}
                <div className='pagination-block'>
                    <Pagination
                        currentPage={currentPage}
                        itemCount={_.values(monthlyStatements).length}
                        onClickPage={this.onClickPage}
                        perPage={perPage}
                        showChangePerPage={false}
                    />
                </div>
            </div>
        );
    }

    private onClickPage(page: number): void {
        this.setState({
            currentPage: page,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        monthlyStatements: monthlyStatementsSelector(state, AccountTypeEnum.IncomeTrust),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        monthlyStatementsList: () => dispatch(monthlyStatementsListAction(AccountTypeEnum.IncomeTrust)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MonthlyStatements);
