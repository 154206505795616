import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import Cookies from 'js-cookie';
import _ from 'lodash';
import IAggregator from '~Api/Broker/IAggregator';
import IBroker from '~Api/Broker/IBroker';
import { parseAggregator, parseBroker } from '~Api/Broker/parsers';
import {
    aggregatorListRequest,
    brokerAddRequest,
    brokerCompleteRequest,
    brokerInsuranceProofRequest,
    brokerMembershipDocumentRequest,
    brokerPhotoIdRequest,
} from '~Api/Broker/requests';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    parseAccount,
    parseInvestor,
} from '~Api/Investor/parsers';
import {
    investorAccountCompleteRequest,
    investorAccountIncomeTrustInterestPaymentStrategyRequest,
    investorAccountMarketplaceQualifierRequest,
    investorGetRequest,
    investorReferralsTokenRequest,
    investorUpdateRequest,
} from '~Api/Investor/requests';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import { parseReferralPartner } from '~Api/ReferralPartner/parsers';
import {
    referralPartnerAddRequest,
    referralPartnerCompleteRequest,
    referralPartnerFileAddRequest,
} from '~Api/ReferralPartner/requests';
import {
    authRegisterErrorsSetAction,
    authRegisterInProgressSetAction,
} from '~Auth/actions';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import { currentInvestorSelector } from '~Investor/selectors';
import {
    currentInvestorAccountIncomeTrustSetAction,
    currentInvestorAccountMarketplaceSetAction,
} from '~Investor/actions';
import {
    IPublicAdviserRegisterAction,
    IPublicAggregatorsGetAction,
    IPublicCountriesGetAction,
    IPublicCurrentBrokerRegisterAction,
    IPublicCurrentInvestorAccountIncomeTrustCompleteAction,
    IPublicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction,
    IPublicCurrentInvestorAccountMarketplaceCompleteAction,
    IPublicCurrentInvestorAccountMarketplaceQualifierSendAction,
    IPublicCurrentInvestorBankAccountValueSetAction,
    IPublicCurrentInvestorCompanyValueSetAction,
    IPublicCurrentInvestorGetAction,
    IPublicCurrentInvestorIndividualValueSetAction,
    IPublicCurrentInvestorSendAction,
    IPublicCurrentInvestorTrustValueSetAction,
    IPublicCurrentInvestorValueSetAction,
    IPublicInvestReferredAction,
    IPublicReferralPartnerRegisterAction,
    publicAdviserRegisterErrorsSetAction,
    publicAdviserRegisterInProgressSetAction,
    publicAggregatorsSetAction,
    publicCountriesSetAction,
    publicCurrentInvestorSendAction,
    publicCurrentInvestorSetAction,
} from '~Public/actions';
import { parseCountry } from '~Public/parsers';
import { IFetchResponse } from '~utilities/fetch';
import ActionsEnum from './ActionsEnum';
import ICountry from './ICountry';
import { publicCountryListRequest } from './requests';
import {
    publicCurrentInvestorAccountSelector,
    publicCurrentInvestorSelector,
} from './selectors';
import { adviserAddRequest } from '~Api/Adviser/requests';
import { doLogin } from '~Auth/sagas';

function* adviserRegister(action: IPublicAdviserRegisterAction): Iterator<unknown> {
    yield put(publicAdviserRegisterInProgressSetAction(true));

    let unlock: boolean = true;

    const adviserAddResponse: IFetchResponse = yield call(adviserAddRequest, {
        ...action.adviser,
    });

    if (adviserAddResponse.status === 422) {
        yield put(publicAdviserRegisterErrorsSetAction(adviserAddResponse.body));
    } else if (adviserAddResponse.status === 200) {
        history.push('/adviser/register/thanks');
        unlock = false;
    }

    if (unlock) {
        yield put(publicAdviserRegisterInProgressSetAction(false));
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* aggregatorsGet(action: IPublicAggregatorsGetAction): Iterator<unknown> {
    const rawAggregators: IFetchResponse = yield call(aggregatorListRequest);
    const aggregators: IAggregator[] = rawAggregators.body.map(parseAggregator);
    yield put(publicAggregatorsSetAction(aggregators));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* countriesGet(action: IPublicCountriesGetAction): Iterator<unknown> {
    const rawCountries: IFetchResponse = yield call(publicCountryListRequest);
    const countries: ICountry[] = rawCountries.body.map(parseCountry);
    yield put(publicCountriesSetAction(countries));
}

function* currentBrokerRegister(action: IPublicCurrentBrokerRegisterAction): Iterator<unknown> {
    yield put(authRegisterInProgressSetAction(true));

    let unlock: boolean = true;

    const landingPage: string = Cookies.getJSON('landing');

    const rawBroker: IFetchResponse = yield call(brokerAddRequest, {
        ...action.broker,
        landingPage,
    });

    if (rawBroker.status === 422) {
        yield put(authRegisterErrorsSetAction(rawBroker.body));
    } else if (rawBroker.status === 200) {
        const token: string = rawBroker.headers.get('Funding-Auth-Token');
        doLogin(token);
        Cookies.set('jwt_token', token);

        const broker: IBroker = parseBroker(rawBroker.body);

        if (action.membershipDocument) {
            yield call(brokerMembershipDocumentRequest, broker.uuid, action.membershipDocument);
        }
        if (action.photoId) {
            yield call(brokerPhotoIdRequest, broker.uuid, action.photoId);
        }
        if (action.insuranceProof) {
            yield call(brokerInsuranceProofRequest, broker.uuid, action.insuranceProof);
        }

        yield call(brokerCompleteRequest, broker.uuid);

        history.push('/broker/register/thanks');

        unlock = false;
    }

    if (unlock) {
        yield put(authRegisterInProgressSetAction(false));
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorGet(action: IPublicCurrentInvestorGetAction): Iterator<unknown> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const rawInvestor: IFetchResponse = yield call(investorGetRequest, currentUser.currentInvestorUuid);
        const investor: IInvestor = parseInvestor(rawInvestor.body);
        yield put(publicCurrentInvestorSetAction(investor));
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorSend(action: IPublicCurrentInvestorSendAction): Iterator<unknown> {
    const investor: IInvestor = yield select(publicCurrentInvestorSelector);
    yield call(investorUpdateRequest, investor);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorValueSet(action: IPublicCurrentInvestorValueSetAction): Iterator<unknown> {
    yield put(publicCurrentInvestorSendAction());
}

function* currentInvestorAccountMarketplaceQualifierSend(action: IPublicCurrentInvestorAccountMarketplaceQualifierSendAction): Iterator<any> {
    const currentInvestor: IInvestor = yield select(currentInvestorSelector);
    const marketplaceAccount: IAccount = _.find(currentInvestor.accounts, { accountType: AccountTypeEnum.Marketplace });
    yield call(investorAccountMarketplaceQualifierRequest, marketplaceAccount.uuid, action.qualifierResponses);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorBankAccountValueSet(action: IPublicCurrentInvestorBankAccountValueSetAction): Iterator<unknown> {
    yield put(publicCurrentInvestorSendAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorCompanyValueSet(action: IPublicCurrentInvestorCompanyValueSetAction): Iterator<unknown> {
    yield put(publicCurrentInvestorSendAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorIndividualValueSet(action: IPublicCurrentInvestorIndividualValueSetAction): Iterator<unknown> {
    yield put(publicCurrentInvestorSendAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorTrustValueSet(action: IPublicCurrentInvestorTrustValueSetAction): Iterator<unknown> {
    yield put(publicCurrentInvestorSendAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorAccountIncomeTrustComplete(action: IPublicCurrentInvestorAccountIncomeTrustCompleteAction): Iterator<unknown> {
    yield put(authRegisterInProgressSetAction(true));

    let unlock: boolean = true;

    const incomeTrustAccount: IAccount = yield select(publicCurrentInvestorAccountSelector, AccountTypeEnum.IncomeTrust);
    const investorAccountCompleteResponse: IFetchResponse = yield call(investorAccountCompleteRequest, incomeTrustAccount.uuid, action.imContent);

    if (investorAccountCompleteResponse.status === 422) {
        yield put(authRegisterErrorsSetAction(investorAccountCompleteResponse.body));
    } else if (investorAccountCompleteResponse.status === 200) {
        const investorAccountIncomeTrust: IAccount = parseAccount(investorAccountCompleteResponse.body);
        yield put(currentInvestorAccountIncomeTrustSetAction(investorAccountIncomeTrust));

        history.push('/');

        unlock = false;
    }

    if (unlock) {
        yield put(authRegisterInProgressSetAction(false));
    }
}

function* currentInvestorAccountIncomeTrustInterestPaymentStrategySet(action: IPublicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction): Iterator<unknown> {
    const investorAccountIncomeTrust: IAccount = yield select(publicCurrentInvestorAccountSelector, AccountTypeEnum.IncomeTrust);

    const investorAccountIncomeTrustInterestPaymentStrategyResponse: IFetchResponse = yield call(investorAccountIncomeTrustInterestPaymentStrategyRequest, investorAccountIncomeTrust.uuid, action.interestPaymentStrategy);
    const investor: IInvestor = parseInvestor(investorAccountIncomeTrustInterestPaymentStrategyResponse.body);
    yield put(publicCurrentInvestorSetAction(investor));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentInvestorAccountMarketplaceComplete(action: IPublicCurrentInvestorAccountMarketplaceCompleteAction): Iterator<unknown> {
    yield put(authRegisterInProgressSetAction(true));

    let unlock: boolean = true;

    const marketplaceAccount: IAccount = yield select(publicCurrentInvestorAccountSelector, AccountTypeEnum.Marketplace);
    const investorAccountCompleteResponse: IFetchResponse = yield call(investorAccountCompleteRequest, marketplaceAccount.uuid, null, action.pdsContent,);

    if (investorAccountCompleteResponse.status === 422) {
        yield put(authRegisterErrorsSetAction(investorAccountCompleteResponse.body));
    } else if (investorAccountCompleteResponse.status === 200) {
        const investorAccountMarketplace: IAccount = parseAccount(investorAccountCompleteResponse.body);
        yield put(currentInvestorAccountMarketplaceSetAction(investorAccountMarketplace));

        history.push('/');

        unlock = false;
    }

    if (unlock) {
        yield put(authRegisterInProgressSetAction(false));
    }
}

function* investReferred(action: IPublicInvestReferredAction): Iterator<unknown> {
    const rawRefer: IFetchResponse = yield call(investorReferralsTokenRequest, action.referrerCode);

    Cookies.set('refer', rawRefer.body, {
        expires: 30,
    });

    history.push('/invest/marketplace');
}

function* referralPartnerRegister(action: IPublicReferralPartnerRegisterAction): Iterator<unknown> {
    yield put(authRegisterInProgressSetAction(true));

    let unlock: boolean = true;

    const referralPartnerResponse: IFetchResponse = yield call(referralPartnerAddRequest, action.referralPartner);

    if (referralPartnerResponse.status === 422) {
        yield put(authRegisterErrorsSetAction(referralPartnerResponse.body));
    } else if (referralPartnerResponse.status === 200) {
        const token: string = referralPartnerResponse.headers.get('Funding-Auth-Token');
        doLogin(token);
        Cookies.set('jwt_token', token);

        const referralPartner: IReferralPartner = parseReferralPartner(referralPartnerResponse.body);

        yield call(referralPartnerFileAddRequest, referralPartner, action.photoId);

        yield call(referralPartnerCompleteRequest, referralPartner);

        history.push('/partner/register/thanks');

        unlock = false;
    }

    if (unlock) {
        yield put(authRegisterInProgressSetAction(false));
    }
}

export function* PublicSagas(): Iterator<unknown> {
    yield all([
        takeEvery(ActionsEnum.AdviserRegister, adviserRegister),

        takeEvery(ActionsEnum.AggregatorsGet, aggregatorsGet),

        takeEvery(ActionsEnum.CountriesGet, countriesGet),

        takeEvery(ActionsEnum.CurrentBrokerRegister, currentBrokerRegister),

        takeEvery(ActionsEnum.CurrentInvestorGet, currentInvestorGet),
        debounce(500, ActionsEnum.CurrentInvestorSend, currentInvestorSend),

        takeEvery(ActionsEnum.CurrentInvestorAccountMarketplaceQualifierSend, currentInvestorAccountMarketplaceQualifierSend),

        takeEvery(ActionsEnum.CurrentInvestorAccountIncomeTrustComplete, currentInvestorAccountIncomeTrustComplete),
        takeEvery(ActionsEnum.CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet, currentInvestorAccountIncomeTrustInterestPaymentStrategySet),

        takeEvery(ActionsEnum.CurrentInvestorAccountMarketplaceComplete, currentInvestorAccountMarketplaceComplete),

        takeEvery(ActionsEnum.CurrentInvestorBankAccountValueSet, currentInvestorBankAccountValueSet),

        takeEvery(ActionsEnum.CurrentInvestorCompanyValueSet, currentInvestorCompanyValueSet),

        takeEvery(ActionsEnum.CurrentInvestorValueSet, currentInvestorValueSet),

        takeEvery(ActionsEnum.CurrentInvestorTrustValueSet, currentInvestorTrustValueSet),

        takeEvery(ActionsEnum.CurrentInvestorIndividualValueSet, currentInvestorIndividualValueSet),

        debounce(200, ActionsEnum.InvestReferred, investReferred),

        takeEvery(ActionsEnum.ReferralPartnerRegister, referralPartnerRegister),
    ]);
}
