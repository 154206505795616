import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { ReactElement } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { BiDownArrowAlt } from 'react-icons/bi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import IInvestor from '~Api/Investor/IInvestor';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';
import {
    annualStatementsListAction,
    currentInvestorGetAction,
    incomeTrustsListAction,
    monthlyStatementsListAction,
} from '~Investor/actions';
import {
    currentInvestorAccountAnnualStatementsSelector,
    currentInvestorAccountSelector,
    currentInvestorSelector,
    incomeTrustsSelector,
    monthlyStatementsSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import DisclosureDocumentsIcon from '~UI/DisclosureDocuments';
import StatementsIcon from '~UI/Statements';
import { currentUserGetAction } from '~User/actions';
import IUser from '~User/IUser';
import { currentUserSelector } from '~User/selectors';
import { IDictionary } from '~utilities/IDictionary';
import '../dashboard.less';
import Layout from './Layout';
import IncomeTrustClassTypeLabels from '~Api/Investor/IncomeTrustClassTypeLabels';
import dayjs, { Dayjs } from 'dayjs';

interface IPropsSelector {
    annualStatements: IDictionary<IAnnualStatement>;
    currentInvestor: IInvestor;
    currentUser: IUser;
    incomeTrustAccount: IAccount;
    incomeTrusts: IDictionary<IIncomeTrust>;
    monthlyStatements: IDictionary<IMonthlyStatement>;
}

interface IPropsDispatch {
    annualStatementsList: () => void;
    currentInvestorGet: () => void;
    currentUserGet: () => void;
    incomeTrustsList: () => void;
    monthlyStatementsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Dashboard extends React.Component<Props> {
    public componentDidMount(): void {
        const { annualStatements, currentInvestor, currentUser, incomeTrusts, monthlyStatements } = this.props;

        if (!annualStatements) {
            this.props.annualStatementsList();
        }

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!currentUser) {
            this.props.currentUserGet();
        }

        if (!incomeTrusts) {
            this.props.incomeTrustsList();
        }

        if (!monthlyStatements) {
            this.props.monthlyStatementsList();
        }
    }

    public render(): JSX.Element {
        const { annualStatements, currentInvestor, currentUser, incomeTrustAccount, incomeTrusts, monthlyStatements } = this.props;

        if (!currentInvestor || !currentUser || !annualStatements || !incomeTrusts || !monthlyStatements) {
            return (
                <Layout section='dashboard'>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        const displayName: string = currentUser.firstName || currentInvestor.name;

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const principalBalanceTotal: number = _.reduce(incomeTrusts, (sum: number, incomeTrust: IIncomeTrust) => sum + incomeTrust.principalBalance, 0);

        const incomeTrustBoxes: JSX.Element[] = _.map(incomeTrusts, (incomeTrust: IIncomeTrust) => {
            if (![IncomeTrustClassTypeEnum.ClassC, IncomeTrustClassTypeEnum.ClassD].includes(incomeTrust.classType)) {
                return;
            }

            return (
                <div className='box target-return'>
                    <div className='primary'>
                        <div className='label'>{IncomeTrustClassTypeLabels[incomeTrust.classType]} Target Return</div>
                        <div className='value'>{percentageFormatter.format(incomeTrust.interestRate)}*</div>
                    </div>
                    <div className='secondary'>
                        <div className='label'>Principal Invested</div>
                        <div className='value'>{numeral(incomeTrust.principalBalance).format('$0,0[.]00')}</div>
                    </div>
                </div>
            );
        });

        const latestAnnualStatement: IAnnualStatement = _.first(_.orderBy(annualStatements, ['year'], ['desc']));
        const latestMonthlyStatement: IMonthlyStatement = _.first(_.orderBy(monthlyStatements, ['month', 'year'], ['desc', 'desc']));

        const latestAnnualStatementButton: JSX.Element = latestAnnualStatement && (
            <Button
                href={`${process.env.API_HOST}/investor-annual-statements/${latestAnnualStatement.uuid}/download`}
                target='_blank'
            >
                FY{latestAnnualStatement.year} Annual Income Statement <BiDownArrowAlt />
            </Button>
        );

        const latestMonthlyStatementButton: JSX.Element = latestMonthlyStatement && (
            <Button
                href={`${process.env.API_HOST}/investor-account-monthly-statements/${latestMonthlyStatement.uuid}/download`}
                target='_blank'
            >
                {moment(latestMonthlyStatement.month).format('MMMM')} {latestMonthlyStatement.year} Monthly Statement<BiDownArrowAlt />
            </Button>
        );

        const holidayBreakStart: Dayjs = dayjs('12-20-2024');
        const holidayBreakEnd: Dayjs = dayjs('01-06-2025');
        const today: Dayjs = dayjs().tz('Australia/Brisbane');

        const holidayBreakBlock: ReactElement = today.isAfter(holidayBreakStart.subtract(7, 'day'), 'day') && today.isBefore(holidayBreakEnd, 'day') ? (
            <Alert>
                <Alert.Heading>Holiday Season 🎄</Alert.Heading>
                <p>The Funding office will be closed from <strong>{holidayBreakStart.format('dddd Do MMMM YYYY')}</strong> and will reopen on <strong>{holidayBreakEnd.format('dddd Do MMMM YYYY')}</strong>. During this period, we may experience a delay in responding to your inquiries as we give our team members a well-deserved opportunity to rest and spend time with their families.</p>
                <p>We appreciate your patience and understanding and will make every effort to assist you as soon as possible upon our return.</p>
                <p>Wishing you a wonderful holiday season.</p>
            </Alert>
        ) : null;

        return (
            <Layout section='dashboard'>
                <h1>👋 Hi, {displayName}</h1>
                {holidayBreakBlock}
                <div className='content-box portfolio-snapshot'>
                    <h2>Entire Portfolio Snapshot</h2>
                    <div className='boxes'>
                        {incomeTrustBoxes}
                        <div className='box total-income'>
                            <div className='primary'>
                                <div className='label'>Total Income</div>
                                <div className='value'>{numeral(incomeTrustAccount.interestAmountEarned).format('$0,0[.]00')}</div>
                            </div>
                            <div className='secondary'>
                                <div className='label'>Total Invested</div>
                                <div className='value'>{numeral(principalBalanceTotal).format('$0,0[.]00')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dual-box'>
                    <div className='content-box latest-statements'>
                        <StatementsIcon />
                        <h2>Latest Statements</h2>
                        {latestAnnualStatementButton}
                        {latestMonthlyStatementButton}
                        {!latestAnnualStatement && !latestMonthlyStatement && <p>No available statements</p>}
                    </div>
                    <div className='content-box disclosure-documents'>
                        <DisclosureDocumentsIcon />
                        <h2>Downloadable Documents</h2>
                        <p>Please ensure you read and understand these documents before making an investment. Contact our investment team if you have any general information questions.</p>
                        <Button href='https://www.funding.com.au/funding-income-trust-information-memorandum/' target='_blank'>Information Memorandum <BiDownArrowAlt /></Button>
                        <Button href='https://www.funding.com.au/funding-income-trust-accountant-declaration-letter/' target='_blank'>Accountant Declaration Letter <BiDownArrowAlt /></Button>
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        annualStatements: currentInvestorAccountAnnualStatementsSelector(state, AccountTypeEnum.IncomeTrust),
        currentInvestor: currentInvestorSelector(state),
        currentUser: currentUserSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        incomeTrusts: incomeTrustsSelector(state),
        monthlyStatements: monthlyStatementsSelector(state, AccountTypeEnum.IncomeTrust),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        annualStatementsList: () => dispatch(annualStatementsListAction(AccountTypeEnum.IncomeTrust)),
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        currentUserGet: () => dispatch(currentUserGetAction()),
        incomeTrustsList: () => dispatch(incomeTrustsListAction()),
        monthlyStatementsList: () => dispatch(monthlyStatementsListAction(AccountTypeEnum.IncomeTrust)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
